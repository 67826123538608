body {
  margin: 0;
  padding: 0;
  font-family: 'Gudea', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.NavBarLogo {
  height: 48px;
  width: auto;      
}

@media screen and (min-width: 960px) {
  html {
      margin-left: calc(100vw - 100%);
      margin-right: 0;
  }
}