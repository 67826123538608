body {
  margin: 0;
  padding: 0;
  font-family: 'Gudea', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.NavBarLogo {
  height: 48px;
  width: auto;      
}

@media screen and (min-width: 960px) {
  html {
      margin-left: calc(100vw - 100%);
      margin-right: 0;
  }
}
.textbox {
    background-color: rgba(255, 255, 255, 0.9);
}

.logo {
    width: 300px;
    height: auto;
}
.textbox {
    background-color: rgba(255, 255, 255, 0.9);
}
.carouselRoot {
    background-color: black; 
}

.plakat {
    height: auto;
    width: 800px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}


.shape {
    width: 100%;
    height: auto;
}

.shapeShowroom {
    height: 1440px;
    float: left;
    /* demo monitors: 2560x1440 */
}

.imgParent {
    position: relative;
}

.scissorShowroom {
    position: absolute;
    width:1920px;
    height: 1440px;
}

.scissor {
    position: absolute;
    width: 100%;
    height: auto;
}

.captionBox {
}

.carousel-control-prev-icon {
    -webkit-filter: invert(100%);
            filter: invert(100%);
}


.carousel-control-next-icon {
    -webkit-filter: invert(100%);
            filter: invert(100%);
}


.captionText {
    text-align: center;
    font-size: 20px;

}
.captionBoxShowroom {
    margin-right: 0%;
    float: right;
    width: 640px;
    height: 1440px;
}

.captionTextShowroom {
    margin-left: 32px;
    margin-right: 32px;
    height: 900px;
    margin-top: 32px;
    margin-bottom: 32px;
    position: absolute;
    bottom: 0;
    color: black;
    font-size: 40px;
}
.textbox {
    background-color: rgba(255, 255, 255, 0.9);
}

.logo {
    width: 300px;
    height: auto;
}
